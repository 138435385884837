import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTransition, animated } from "react-spring";
import ChartTip from "./ChartTip";
import formatter from "../../utils/formatters/formatter";

const arrowSize = 12;
const padding = 4;

const TooltipContainer = styled.div(
  ({ left, bottom }) => `
    position: absolute;
    left: ${left}px;
    bottom: ${bottom + arrowSize + padding}px;
    z-index: 100000;
    pointer-events: none;
    width: 0px;
`
);

const TooltipLabel = styled.span`
  font-weight: 400;
  color: #04a1f2;
`;

const TooltipValue = styled.span`
  padding-left: 8px;
  font-weight: 700;
`;

const TooltipSection = styled.div`
  position: relative;
  background: black;
  color: white;
  padding: 18px;
  width: 240px;
  left: -120px;
  z-index: 10000;
  border-radius: 2px;
  font-size: 13px;
  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-color: black transparent;
    border-style: solid;
    border-width: ${arrowSize}px ${arrowSize}px 0 ${arrowSize}px;
    left: 108px;
    bottom: -${arrowSize}px;
  }
`;

export default function Tooltip(props) {
  const { data, left, bottom, config, tooltip, metaFields } = props;

  const { skipAnimation, skipTooltipConfig } = tooltip || {};

  const transitions = useTransition(data, {
    from: {
      opacity: skipAnimation ? 1 : 0,
      top: skipAnimation ? 0 : 4,
      position: "relative",
    },
    enter: { opacity: 1, top: skipAnimation ? 0 : -3, position: "relative" },
    leave: { opacity: 0 },
    unique: true,
  });

  const getDisplayValue = (value, type) => {
    if (!type) return value;
    const isString = ["number", "count", "string"];
    return isString.find((s) => s === type) ? value : formatter(value, type, 1);
  };

  const renderTooltip = ({ tooltip = [] }) => {
    return tooltip.map(({ value, label, type }, index) => (
      <div key={label + value + index}>
        <TooltipLabel data-cy="chart-tip-label">{label}:</TooltipLabel>
        <TooltipValue data-cy="chart-tip-value">
          {getDisplayValue(value, type)}
        </TooltipValue>
        <br />
      </div>
    ));
  };

  return transitions((styles, item) => (
    <animated.div style={styles}>
      <TooltipContainer left={left} bottom={bottom}>
        <TooltipSection>
          {config && !skipTooltipConfig ? (
            <ChartTip
              config={config}
              data={data}
              tooltip={tooltip}
              metaFields={metaFields}
            />
          ) : (
            renderTooltip(item)
          )}
        </TooltipSection>
      </TooltipContainer>
    </animated.div>
  ));
}

Tooltip.propTypes = {
  data: PropTypes.object.isRequired,
  left: PropTypes.number,
  bottom: PropTypes.number,
};
